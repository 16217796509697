import * as actionTypes from '../actions/types/securityTypes';

export interface ISecurityReducer {
    token: string | undefined;
    userId: string | undefined;
    email: string | undefined;
    authorities: string[] | undefined;
}

const defaultState = (): ISecurityReducer => ({
    token: undefined,
    userId: undefined,
    email: undefined,
    authorities: undefined
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState(), action: any): ISecurityReducer => {
    switch (action.type) {

        case actionTypes.LOGIN_DATA: {
            const payload: actionTypes.ISecurityTypes['LOGIN_DATA'] = action;
            return {
                ...state,
                token: payload.data.token,
                userId: payload.data.userId,
                email: payload.data.email,
                authorities: payload.data.authorities
            };
        }

        case actionTypes.LOGOUT: {
            return {
                ...state,
                token: undefined,
                userId: undefined,
                email: undefined,
                authorities: undefined
            };
        }

        case actionTypes.SET_TOKEN: {
            return {
                ...state,
                token: action.token
            };
        }

        default: {
            return state;
        }
    }
};