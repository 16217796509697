import * as actionTypes from '../actions/types/notificationTypes';

export interface INotificationReducer {
    notificationCount: number;
}

const defaultState = (): INotificationReducer => ({
    notificationCount: 0
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState(), action: any): INotificationReducer => {
    switch (action.type) {

        case actionTypes.GET_NOTIFICATION_COUNT: {
            const payload: actionTypes.INotificationTypes['GET_NOTIFICATION_COUNT'] = action;
            return {
                ...state,
                notificationCount: payload.notificationCount
            };
        }

        default: {
            return state;
        }
    }
};