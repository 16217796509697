import * as actionTypes from '../actions/types/userProfileTypes';
import { LOGOUT } from '../actions/types/securityTypes';
import { IUserConfig, IUserProfile } from '../entities/IUserProfile';

export interface IUserProfileReducer {
    userProfile: IUserProfile | undefined;
    userConfig: IUserConfig | undefined;
    isOrganizator: boolean;
}

const defaultState = (): IUserProfileReducer => ({
    userProfile: undefined,
    userConfig: {
        id: '',
        shopConfig: {
            activeOffersLimit: 50
        }
    },
    isOrganizator: false
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState(), action: any): IUserProfileReducer => {
    switch (action.type) {

        case actionTypes.GET_USER_PROFILE: {
            const payload: actionTypes.IUserProfileTypes['GET_USER_PROFILE'] = action;
            return {
                ...state,
                userProfile: payload.userProfile
            };
        }
        case actionTypes.GET_USER_CONFIG: {
            const payload: actionTypes.IUserProfileTypes['GET_USER_CONFIG'] = action;
            return {
                ...state,
                userConfig: payload.userConfig
            };
        }
        case actionTypes.IS_ORGANIZATOR: {
            const payload: actionTypes.IUserProfileTypes['IS_ORGANIZATOR'] = action;
            return {
                ...state,
                isOrganizator: payload.isOrganizator
            };
        }
        case LOGOUT: {
            return {
                ...state,
                userProfile: undefined,
                userConfig: undefined
            };
        }

        default: {
            return state;
        }
    }
};