export const LOGIN_DATA = 'LOGIN_DATA';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';

export interface ISecurityTypes {
    LOGIN_DATA: {data: {
        token: string;
        userId: string;
        email: string;
        authorities: string[];
    }};
}