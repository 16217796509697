import { combineReducers } from 'redux';

import security from './securityRedecur';
import profile from './profileReducer';
import alert from './alertReducer';
import notification from './notificationReducer';

const store = combineReducers({
    security,
    profile,
    alert,
    notification
});

export type IState = ReturnType<typeof store>;

export default store;