import { EObjectType } from './IArena';
import { IUserProfile } from './IUserProfile';

export interface IPhoto {
    photoUrl: string;
    name: string;
    id: string;
    description: string;
    place: {
        lat: number;
        long: number;
    };
    members: IUserProfile[];
    emotions: EEmotions;
}

export enum ECurrency {
    PL = 'PL'
}

export enum EEmotions {
    CHEERFUL = 'Wesoły',
    SAD = 'Smutny',
    TWISTED = 'Zakręcony',
    ANGRY = 'Zły'
}

export interface IAddress {
    countryISO: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
    street: string;
    number: string;
    phone: string;
    id?: string;
    lat?: number | undefined;
    lng?: number | undefined;
    isArena?: boolean;
}

export interface IAddressObject {
    street_number?: string;
    route?: string;
    neighborhood?: string;
    locality?: string;
    administrative_area_level_1?: string;
    country?: string;
    postal_code?: string;
}

export enum EIcoMedal {
    ORG = 'organizator',
    HELP = 'pomoc'
}

export enum ESeason {
    SPRING = 'Wiosna',
    SUMMER = 'Lato',
    AUTUMN = 'Jesień',
    WINTER = 'Zima'
}

export interface ISocial {
    type: ESocial;
    value: string;
}

export enum ESocial {
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    TWITTER = 'TWITTER',
    WEBSITE = 'WEBSITE',
    YOUTUBE = 'YOUTUBE',
    PHONE = 'PHONE'
}

export const CBallsSize = [
    0.12,
    0.20,
    0.23,
    0.25,
    0.28,
    0.30,
    0.32,
    0.33,
    0.35,
    0.36,
    0.40,
    0.43,
    0.45,
    0.46,
    0.48,
    0.49,
    0.50,
    0.58
];

export const CCompaniesList = [
    'A&K',
    'ACM',
    'ACTION ARMY',
    'AGM',
    'AMOEBA',
    'APS',
    'ARES',
    'RMY',
    'ASG',
    'AVATAR grenade',
    'AY',
    'Airsoft Innovations',
    'Airsoft Pioneer',
    'Armorer Works',
    'Arrow Arms',
    'Arrow Dynamic',
    'Ascend Airsoft',
    'B&W',
    'BO Manufacture',
    'BOLT Airsoft',
    'Bear Paw Production',
    'CAA AIRSOFT DIVISION',
    'Własny CUSTOM',
    'CSI',
    'CYMA',
    'Classic Army',
    'CyberGun',
    'DE',
    'DRAGON',
    'Dave`s Custom Airsoft',
    'Dominator International',
    'Double Bell',
    'Duel Code',
    'Dytac',
    'E&L',
    'EMG',
    'EVOLUTION AIRSOFT',
    'Elite Force',
    'ForceCore Armament',
    'G&G',
    'G&P',
    'GF Custom Division',
    'GFC Guns',
    'GHK',
    'Galaxy',
    'Golden Eagle',
    'HFC',
    'Haw San',
    'ICS',
    'JG/GE',
    'JG',
    'KJ WORKS',
    'KRYTAC',
    'KWA Airsoft',
    'KWC',
    'Kimera',
    'King Arms',
    'LCT',
    'LayLax',
    'MadBull',
    'Maple Leaf',
    'Marushin',
    'Modify',
    'NPO AEG',
    'Novritsch',
    'Nuprol',
    'Oberland Arms',
    'PPS Airsoft',
    'PTS',
    'Poseidon',
    'ROSSI',
    'RWA',
    'Raptor',
    'Raven',
    'Real Sword',
    'RedWolf Custom',
    'S&T ARMAMENT',
    'S-Thunder',
    'SHS',
    'SILVERBACK AIRSOFT',
    'SPARTAC',
    'SRC',
    'SY',
    'Secutor',
    'Sig Sauer',
    'Snow Wolf',
    'Specna Arms',
    'TANAKA',
    'Tactical Game Innovation',
    'Tectonic Innovations',
    'Tokyo Marui',
    'Umarex',
    'Umbrella Armory',
    'VFC',
    'Valken',
    'Vorsk',
    'WELL',
    'WE',
    'WinGun',
    'ZOXNA Airsoft',
    'Other'
];

export enum EAdditionalFields {
    NATIONAL_ID_NUMBER = 'NATIONAL_ID_NUMBER',
    DOCUMENT_ID_NUMBER = 'DOCUMENT_ID_NUMBER',
    DATE_OF_BIRTH = 'DATE_OF_BIRTH',
    ADDRESS = 'ADDRESS',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL'
}

export interface IDefaultData {
    playersLimit: number | undefined | null;
    legal: boolean;
    chrono: boolean; // czy wymagane jest każdorazowe chronowanie
    parking: boolean;
    onlyBioBalls: boolean;
    ageLimit: number | null;
    objectType: EObjectType | null;
    teamColors: {
        red: boolean;
        blue: boolean;
        green: boolean;
        yellow: boolean;
    };
    paymentsEnabled?: boolean;
    paymentsDaysBeforeEnd: Date;
    additionalFieldsOnReservation?: EAdditionalFields[];
}

export interface ISingleTicket {
    createdAt?: Date;
    updatedAt?: Date;
    createdById?: string;
    id?: string | null;
    name: string;
    price: string;
    description: string;
}

export interface ISecurityData {
    token: string;
    userId: string;
    email: string;
    authorities: string[];
}

export enum EPopupSizes {
    Small,
    Medium,
    Big,
    Monster,
    Full
}

export interface ISingleContract {
    createdAt?: Date;
    updatedAt?: Date;
    createdById?: string;
    id?: string;
    name?: string;
    body: string;
    fileId: string;
    language: ELcid;
}

export enum ELcid {
    en = 1033,
    uk = 1058,
    fr = 1036,
    de = 1031,
    pl = 1045,
    cz = 1029,
    ua = 4096,
    lt = 1063,
    sk = 1051,
    it = 1040,
    se = 1053,
    fi = 1035,
    es = 3082
}

export const LangCodeTranslation = {
    1033: 'EN',
    1036: 'FR',
    1058: 'UK',
    1031: 'DE',
    1045: 'PL',
    1029: 'CZ',
    4096: 'UA',
    1063: 'LT',
    1051: 'SK',
    1040: 'IT',
    1053: 'SE',
    1035: 'FI',
    3082: 'ES'
};

export const LanguagesList = {
    1033: 'en',
    1036: 'fr',
    1058: 'uk',
    1031: 'de',
    1045: 'pl',
    1029: 'cz',
    4096: 'ua',
    1063: 'lt',
    1051: 'sk',
    1040: 'it',
    1053: 'se',
    1035: 'fi',
    3082: 'es'
};

export const LangList = ['en', 'fr', 'uk', 'de', 'pl', 'cz', 'ua', 'lt', 'sk', 'it', 'se', 'fi', 'es'];

export interface IStandardSearchBody<T> {
    content: T[];
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
    empty: boolean;
    first: boolean;
    last: boolean;
}

export interface IValue {
    key: string;
    text: string;
    data: any;
}

export interface ISlide {
    src: string;
    width: number;
    height: number;
    srcSet?: any
}

export interface IFile {
    contentType: string;
    createdAt: Date;
    createdById: string;
    description: string;
    displayName: string;
    externalUrl: string;
    id: string;
    permissions: {
        createdAt: Date;
        createdById: string;
        id: string;
        permissionType: 'READ' | 'WRITE';
        permissionValue: string;
        updatedAt: Date;
    }[];
    s3Path: string;
    shortDescription: string;
    updatedAt: Date
}

export interface IPaymentData {
    beneficiaryName: string;
    accountNumber: string;
    taxIdNumber: string;
}