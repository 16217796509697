import { IUserConfig, IUserProfile } from '../../entities/IUserProfile';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_CONFIG = 'GET_USER_CONFIG';
export const IS_ORGANIZATOR = 'IS_ORGANIZATOR';

export interface IUserProfileTypes {
    GET_USER_PROFILE: {
        userProfile: IUserProfile;
    };
    GET_USER_CONFIG: {
        userConfig: IUserConfig;
    }
    IS_ORGANIZATOR: {
        isOrganizator: boolean;
    }
}