import { Dispatch } from 'redux';
import * as queryString from 'query-string-for-all';
import moment from 'moment';

import { IState } from '../reducers';
import { apiUrl } from '../configuration/url';
import { IGetNotificationQuery, INotification } from '../entities/INotification';
import { GET_NOTIFICATION_COUNT } from './types/notificationTypes';

export const createNotification = (body: INotification): Promise<INotification> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/notification`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(body)
    }).then((res: any) => res.json().content).then((response: INotification) => {
        return response;
    });
}) as any;

export const getNotification = (notificationId: string): Promise<INotification> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/notification/${notificationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json().content).then((response: INotification) => {
        return response;
    });
}) as any;

export const editNotification = (notification: string, body: INotification): Promise<INotification> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/notification/${notification}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(body)
    }).then((res: any) => res.json().content).then((response: INotification) => {
        return response;
    });
}) as any;

export const searchAllNotification = (payload?: IGetNotificationQuery): Promise<INotification[]> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    const userProfile = getState().profile.userProfile;
    const queryParams = payload ? queryString.stringify(payload) : '';
    return fetch(`${apiUrl}/api/notification?size=100&${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json()).then((response: {content: INotification[]}) => {
        let systemNotificationCount = 0;
        if (userProfile && !userProfile?.pictureId) {
            systemNotificationCount++;
        }
        if ((userProfile && !userProfile?.nickName)) {
            systemNotificationCount++;
        }
        if (userProfile && (!userProfile?.dateOfBirth || moment().diff(userProfile?.dateOfBirth, 'years') <= 2)) {
            systemNotificationCount++;
        }
        dispatch({
            type: GET_NOTIFICATION_COUNT,
            notificationCount: response.content.filter(elem => !elem.viewed).length + systemNotificationCount
        });
        return response.content;
    });
}) as any;