import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { getPhoto } from '../../actions/globalActions';
import { colorStack } from '../../style/colorStack';
import { Spinner } from './Spinner/Spinner';
import { apiUrl } from '../../configuration/url';

const Wrapper = styled.div<{ rounded: boolean, width: number }>`
    display: flex;
    ${props => props.width > 0 && css`
        width: ${`${props.width}px`};
    `}
    div {
        max-width: 100%;
        ${props => props.rounded && css`
            border-radius: 100%;
        `}
    }
    img {
        width: 100%;
        ${props => props.rounded && css`
            border-radius: 100%;
        `}
    }
`;

const Photo = styled.div<{ url: string, rounded: boolean }>`
    background-color: ${colorStack.ligthGrey};
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    ${props => props.rounded && css`
        border-radius: 100%;
    `}
    ${props => props.url && css`
        background-image: ${`url(${props.url})`};
    `}
`;

interface IProps {
    photoId: string;
    rounded?: boolean;
    width?: number;
    isImg?: boolean;
}

export const PhotoComponent: FC<IProps> = props => {
    const dispatch = useDispatch();
    const [photoUrl, setPhotoUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if (props.photoId?.length > 0) {

            dispatch<any>(getPhoto(props.photoId)).then((res: any) => {
                setPhotoUrl(res.s3Path);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [props.photoId]);

    return (
        <Wrapper className="photo-component" rounded={props.rounded || false} width={props.width || 0}>
            {!loading ? (
                <>
                    {photoUrl && (
                        <>
                            {props.isImg ? (
                                <img src={`${apiUrl}/${photoUrl}`} loading="lazy" />
                            ) : (
                                <Photo rounded={props.rounded || false} url={`${apiUrl}/${photoUrl}`} />
                            )}
                        </>
                    )}
                </>
            ) : (
                <Spinner size="small" />
            )}
        </Wrapper>
    );
};