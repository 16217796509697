import { IAlert } from '../entities/IAlert';
import { ADD_ALERT, UPDATE_ALERT, REMOVE_ALERT } from '../actions/types/alertTypes';

export interface IAlertReducer {
    alertsById: {
        [id: string]: IAlert;
    };
    alertsIds: string[];
}

const defaultState = (): IAlertReducer => ({
    alertsById: {},
    alertsIds: []
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState(), action: any): IAlertReducer => {
    switch (action.type) {
        case ADD_ALERT: {
            const alert = action.alert as IAlert;
            return {
                alertsById: {
                    ...(state.alertsById || {}),
                    [alert.id]: alert
                },
                alertsIds: [alert.id, ...(state.alertsIds || [])]
            };
        }

        case UPDATE_ALERT: {
            const alert = action.alert as IAlert;
            return {
                ...state,
                alertsById: {
                    ...(state.alertsById || {}),
                    [action.alertId]: {
                        ...state.alertsById[action.alertId],
                        ...alert
                    }
                }
            };
        }

        case REMOVE_ALERT: {
            const { [action.alertId]: removedAlert, ...alertsById } = state.alertsById;
            return {
                alertsIds: state.alertsIds.filter(alertId => alertId !== action.alertId),
                alertsById
            };
        }

        default: {
            return state;
        }
    }
};
