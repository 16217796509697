import { Dispatch } from 'redux';
import { saveAs } from 'file-saver';

import { IState } from '../reducers';
import { apiUrl, baseUrl } from '../configuration/url';
import { IFile } from '../entities/IGlobal';
import { IS_ORGANIZATOR } from './types/userProfileTypes';

export const uploadFile = (file: File, fileName?: string, description?: string): Promise<IFile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    const form = new FormData();
    form.append('file', file);
    form.append('metadata', `${JSON.stringify({
        displayName: fileName || 'name',
        description: description || 'someDescription over Here'
    })};type=application/json`);
    const headers = new Headers();
    headers.append('Authorization', token || '');
    return fetch(`${apiUrl}/api/file`, {
        method: 'POST',
        headers: headers,
        body: form
    }).then(response => response.json()).then(r => r);
}) as any;

export const getPhoto = (photoId: string): Promise<any> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/file/${photoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then(response => response.json());
}) as any;

export const getFile = (fileId: string): Promise<IFile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/file/${fileId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then(response => response.json()).then((res) => {
        let a = document.createElement('a');
        a.setAttribute('target', '_blank');
        a.href = `${apiUrl}/${res.s3Path}`;
        a.setAttribute('download', res.s3Path.split('/')[res.s3Path.split('/').length - 1]);
        a.click();
    });
}) as any;

export const getWeather = (lat: number, lon: number, days: number = 3): Promise<any> => ((dispatch: Dispatch, getState: () => IState) => {
    return fetch(`https://api.weatherapi.com/v1/forecast.json?key=22b373083ea14b3a95f133409232909&q=${lat},${lon}&days=${days}&lang=pl`, {
        method: 'GET'
    }).then(response => response.json());
}) as any;

export const isOrganizatorUpdate = () => (async (dispatch: Dispatch) => {
    dispatch({
        type: IS_ORGANIZATOR,
        isOrganizator: true
    });
}) as any;