import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { media } from '../../style/breakpoints';
import { TopMenu } from './TopMenu';
import { getCookie } from '../../tools/cookies';
import { getCurrentUser, getUserConfig } from '../../actions/userProfileActions';
import { setToken } from '../../actions/securityActions';
import { Footer } from './Footer';
import { CookiesBanner } from './CookiesBanner';
import { AlertContainer } from './Alert/AlertContainer';
import { searchAllArenas } from '../../actions/arenasAction';
import { searchAllNotification } from '../../actions/notificationsActions';
import { IUserProfile } from '../../entities/IUserProfile';
import { Spinner } from './Spinner/Spinner';
import InitializeGoogleAnalytics from '../../tools/ga';
import { searchAllPermission } from '../../actions/permissionActions';
import { EPermissionObject, IPermission } from '../../entities/IPermission';
import { isOrganizatorUpdate } from '../../actions/globalActions';

const Wrapper = styled.div``;

const Content = styled.div<{ fullWidth: boolean }>`
    width: 100%;
    margin: 0 auto;
    min-height: calc((100vh - 69px) - 52px);
    border-radius: 8px;
    padding: 0 1rem;
    ${media.desktop`
        padding: 0;
    `};
    max-width: 1280px;
    ${props => props.fullWidth && css`
        max-width: 100%;
    `}
`;

interface IProps {
    children: JSX.Element;
}

export const Layout: FC<IProps> = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);

        useEffect(() => {
            InitializeGoogleAnalytics();
         }, []);

    useEffect(() => {
        const tokenFromCookie = getCookie('PlayAirAuth');
        if (tokenFromCookie !== 'undefined' && tokenFromCookie?.length) {
            dispatch<any>(setToken(tokenFromCookie));
            dispatch<any>(getCurrentUser(tokenFromCookie)).then((res: IUserProfile) => {
                dispatch<any>(searchAllNotification({ userId: res?.id || '' }));
                dispatch<any>(getUserConfig()).then(() => {
                    dispatch<any>(searchAllPermission({ userId: res?.id, objectType: EPermissionObject.ARENA })).then((res: IPermission[]) => {
                        const arenasIds = res.map(elem => elem.objectId).filter((uniq, pos, self) => self.indexOf(uniq) === pos);
                        if (arenasIds.length > 0) {
                            dispatch<any>(isOrganizatorUpdate());
                        }
                    });
                });
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Wrapper>
            {!loading ?
                <>
                    <AlertContainer />
                    <TopMenu />
                    <Content fullWidth={(location.pathname.includes('/bazarek') && !location.pathname.includes('/offer')) || location.pathname.includes('/chats') || location.pathname.includes('/administration') || location.pathname === '/' || location.pathname.includes('profile') || location.pathname.includes('team/')}>
                        {props.children}
                    </Content>
                    <Footer />
                    <CookiesBanner />
                </>
                : <>
                    <br />
                    <br />
                    <br />
                    <Spinner size="large" label="Ładowanie danych" />
                </>}
        </Wrapper>
    );
};