export interface IPermission {
    id?: string;
    objectId: string;
    objectType: EPermissionObject;
    userId: string;
    permissionType: EPermissionType;
    permissionScope: EPermissionScope;
    createdAt: Date;
    createdById: string;
}

export interface IAddAdmin {
    objectId: string;
    userId: string;
    objectType: EPermissionObject;
}

export enum EPermissionObject {
    ARENA = 'ARENA',
    EVENT = 'EVENT',
    TEAM = 'TEAM',
    INVITATION = 'INVITATION',
    MEMBER = 'MEMBER',
    TRANSACTION = 'TRANSACTION',
    POST = 'POST',
    BIG_EVENT = 'BIG_EVENT',
    FACTION = 'FACTION'
}

export enum EPermissionType {
    READ = 'READ',
    WRITE = 'WRITE'
}

export enum EPermissionScope {
    ALL = 'ALL',
    INDIVIDUAL = 'INDIVIDUAL',
    TEAM = 'TEAM',
    FACTION = 'FACTION'
}

export const permissions = [
    { value: 'member', label: 'Odczyt' },
    { value: 'admin', label: 'Odczyt/Edycja' }
];