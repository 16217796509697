import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { IState } from '../../../reducers';
import { IAlertReducer } from '../../../reducers/alertReducer';
import { Alert } from './Alert';
import { media } from '../../../style/breakpoints';

const Wrapper = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    ${media.tabletSm`
        width: auto;
        padding: 0 0 1rem 0;
    `}
`;

export const AlertContainer: FC = () => {
    const { alertsById, alertsIds } = useSelector<IState, IAlertReducer>(state => state.alert);

    return (
        <Wrapper>
            {alertsIds.map((alertId) => (
                <Alert
                    key={alertId}
                    type={alertsById[alertId].type}
                    visible={alertsById[alertId].visible || false}
                    alertId={alertId}
                >
                    {alertsById[alertId].content}
                </Alert>
            ))}
        </Wrapper>
    );
};
