import { Dispatch } from 'redux';

import { ISecurityData } from '../entities/IGlobal';
import { LOGIN_DATA, LOGOUT, SET_TOKEN } from './types/securityTypes';
import { apiUrl } from '../configuration/url';
import { IState } from '../reducers';

export const registerUser = (email: string, password: string, nickName: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch(`${apiUrl}/api/security/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            password: password,
            nickName: nickName
        })
    }).then((res: any) => {
        if (res.status === 200) {
            return true;
        } else {
            return res.json();
        }
    });
}) as any;

export const loginUser = (email: string, password: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch(`${apiUrl}/api/security/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            password
        })
    }).then((res: any) => res.json()).then((response: ISecurityData) => {
        if (response.token) {
            dispatch({
                type: LOGIN_DATA,
                data: {
                    token: response.token,
                    userId: response.userId,
                    email: response.email,
                    authorities: response.authorities
                }
            });
        }
        return response;
    }).catch(e => {
        return true;
    });
}) as any;

export const logout = () => (dispatch: Dispatch) => {
    dispatch({ type: LOGOUT });
};

export const setToken = (token: string) => (dispatch: Dispatch) => {
    dispatch({ type: SET_TOKEN, token: token });
};

export const validateCaptcha = (token: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch(`${apiUrl}/api/security/validate-captcha`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token
        })
    }).then((res: any) => res.json()).then((response: any) => {
        return response;
    }).catch(e => {
        return false;
    });
}) as any;

export const sendEmailResetPassword = (token: string, email: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch(`${apiUrl}/api/security/send-password-reset-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token,
            email
        })
    }).then((res: any) => res.json()).then((response: any) => {
        return response;
    }).catch(e => {
        return true;
    });
}) as any;

export const resetPassword = (token: string, newPassword: string, userId: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    return fetch(`${apiUrl}/api/security/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token,
            userId,
            newPassword
        })
    }).then((res: any) => res.json()).then((response: any) => {
        return response;
    }).catch(e => {
        return true;
    });
}) as any;

export const confirmEmail = (token: string, userId: string): Promise<ISecurityData> => ((dispatch: Dispatch) => {
    console.log(token, userId);
    return fetch(`${apiUrl}/api/security/confirm-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token,
            userId
        })
    }).then((res: any) => res.json()).then((response: ISecurityData) => {
        if (response.email) {
            dispatch({
                type: LOGIN_DATA,
                data: {
                    token: response.token,
                    userId: response.userId,
                    email: response.email,
                    authorities: response.authorities
                }
            });
        }
        return response;
    }).catch(e => {
        return true;
    });
}) as any;

export const changePassword = (oldPassword: string, newPassword: string): Promise<ISecurityData> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/security/change-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify({
            oldPassword,
            newPassword
        })
    }).then((res: any) => res.json()).then((response: any) => {
        return response;
    }).catch(e => {
        return true;
    });
}) as any;